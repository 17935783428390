<script lang="ts">
  // Init client side in HTML-head.
  import { promo, user, userDoc, inviteCode } from "@/lib/client/stores";
  import { paddle } from "@/lib/client/paddle";
  import { auth } from "@/lib/client/firebase";
  import { onAuthStateChanged, type User } from "firebase/auth";
  import {
    getDoc,
    serverTimestamp,
    setDoc,
    updateDoc,
  } from "firebase/firestore";
  import { trackOnAuth, trackSignup } from "@/lib/client/analytics";
  import { getUserDoc } from "@/lib/client/db";
  import { onMount } from "svelte";
  import { getMidnight, isAtLeastYesterdayMidnight } from "@/lib/utils/date";
  import { getCurrentLocale } from "@/lib/utils/i18n";
  import { Capacitor } from "@capacitor/core";

  // Capactior
  onMount(() => console.debug("cap platform:", Capacitor.getPlatform()));

  // search params
  onMount(() => {
    const params = new URLSearchParams(window.location.search);

    // promo
    if (params.has("promo")) promo.set(params.get("promo")!);

    // sangha invite code
    if (params.has("x")) inviteCode.set(params.get("x")!);
  });

  // auth state change
  onMount(() => onAuthStateChanged(auth(), authStateChanged));
  async function authStateChanged(u: User | null) {
    console.debug("authStateChanged - user:", u);
    user.set(u);
    trackOnAuth(u);

    // logged in
    if (u) {
      // remove search params
      let currentUrl = new URL(window.location.href);
      currentUrl.search = "";
      window.history.pushState(null, "", currentUrl.toString());

      try {
        // get user doc
        const ref = getUserDoc(u.uid);
        const doc = (await getDoc(ref)).data();

        // new user
        const isNewUser = doc === undefined;
        if (isNewUser) trackSignup(u);

        // streak updates
        const shouldResetStreak =
          doc?.lastActiveAt && !isAtLeastYesterdayMidnight(doc.lastActiveAt);
        const streakStartAt =
          !doc?.streakStartAt || shouldResetStreak
            ? getMidnight()
            : doc.streakStartAt;

        // update user doc
        await setDoc(
          ref,
          {
            id: u.uid,
            lastActiveAt: serverTimestamp(),
            streakStartAt,
            language: getCurrentLocale(),
          },
          { merge: true },
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  $: if (!import.meta.env.SSR) {
    // promo
    if ($promo && $userDoc) {
      if ($promo.toUpperCase() === "LFSPWYBZJZ") {
        // TODO this should be done server-side
        updateDoc(getUserDoc($userDoc.id), { status: "active", promo: $promo });
        console.debug("promo activated", $promo);
      } else {
        console.warn("promo invalid", $promo);
      }
      promo.set(undefined);
    }

    // paddle
    if ($paddle && $user) {
      $paddle.Update({
        pwCustomer: {
          id: $userDoc?.customerId,
          email: $user.email ?? undefined,
        },
      });
    }

    // trigger userDoc subscription
    if ($userDoc) console.debug("update");
  }
</script>
